import React, { useCallback, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ButtonComponent from "../../@extended/button/ButtonComponent";
import "../seller/Seller.css";
import AddIcon from "@mui/icons-material/Add";
import CustomizedModal from "../../@extended/customizedmodal/CustomizedModal";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import CommonTab from "../../@extended/tabs/CommonTab";
import BreadCrumb from "../../@extended/breadcrumbs/Breadcrumbs";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import OrderTablePage from "./OrderTable";
import OrderView from "./OrderView";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
// ! for future icon use
// import HistoryIcon from "@mui/icons-material/History";
import ConfirmOrder from "./ConfirmOrder";
import VehicalInformationOrder from "./VehicalInformationOrder";
import OrderForm from "./OrderForm";
import { ORDER_PAYMENT, ORDER_STATUS } from "../../../actions/orderActions";
// import generatePDF from "react-to-pdf";
import SellerInvoice from "./SellerInvoice";
import BuyerInvoice from "./BuyerInvoice";
import PaymentStatus from "./PaymentStatus";

const OrderModule = () => {
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [confirmOrder, setConfirmOrder] = useState(false);
  const [vehicleDispatched, setVehicleDispatched] = useState(false);
  const [paymentStatusModal, setPaymentStatusModal] = useState(false);
  const [search, setSearch] = useState("");
  const [fetchData, setFetchData] = useState(false);
  // const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  const targetRef = useRef();
  const targetRefBuyer = useRef();

  // const handleGeneratePDFSeller = () => {
  //   // Make the content visible before generating PDF
  //   targetRef.current.style.display = "block";

  //   // Set the content height to 100% of the viewport height
  //   // targetRef.current.style.height = "100vh";

  //   // Generate PDF
  //   // generatePDF(targetRef, { filename: "page.pdf" });
  //   generatePDF(targetRef, {
  //     filename: "page.pdf",
  //     paperSize: "A4",
  //     margin: { top: "40px", right: "40px", bottom: "40px", left: "40px" },
  //   });

  //   // Reset the content height to its original value
  //   // targetRef.current.style.height = "100vh";

  //   // Hide the content again after generating PDF
  //   targetRef.current.style.display = "none";
  // };
  // const handleGeneratePDFBuyer = () => {
  //   // Make the content visible before generating PDF
  //   targetRefBuyer.current.style.display = "block";

  //   // Set the content height to 100% of the viewport height
  //   // targetRef.current.style.height = "100vh";

  //   // Generate PDF
  //   // generatePDF(targetRef, { filename: "page.pdf" });
  //   generatePDF(targetRefBuyer, {
  //     filename: "page.pdf",
  //     paperSize: "A4",
  //     margin: { top: "40px", right: "40px", bottom: "40px", left: "40px" },
  //   });

  //   // Reset the content height to its original value
  //   // targetRef.current.style.height = "100vh";

  //   // Hide the content again after generating PDF
  //   targetRefBuyer.current.style.display = "none";
  // };
  const dispatch = useDispatch();

  const [id, setId] = useState("");
  const userRoleAccess = useSelector(
    (state) => state?.auth?.profileDetails?.user,
  );
  // const orderUpdate = useCallback((data) => {
  //   dispatch({ type: ORDER_UPDATE, payload: data });
  // });

  const orderPayment = useCallback((data) => {
    dispatch({ type: ORDER_PAYMENT, payload: data });
  }, []);
  const orderStatusData = useCallback((data) => {
    dispatch({ type: ORDER_STATUS, payload: data });
  }, []);

  const orderStatus = [
    { name: "Order Confirmed", id: 1 },
    { name: "Order Processed", id: 2 },
    { name: "Vehicle Dispatched", id: 3 },
    { name: "Order Delivered", id: 4 },
    { name: "Canceled", id: 5 },
  ];

  // const cancleCallBack = () => {
  //   setOpen(false);
  //   setId("");
  // };

  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  const columns = [
    {
      field: "no",
      headerName: "No",
      headerClassName: "email-query-header",
      flex: 0.1,
    },
    {
      field: "orderNumber",
      headerName: "Number",
      headerClassName: "email-query-header",
      flex: 0.5,
    },
    {
      field: "orderDate",
      headerName: "Order Date",
      flex: 0.65,
      headerClassName: "email-query-header",
    },
    {
      field: "product",
      headerName: "Product",
      flex: 1,
      headerClassName: "email-query-header",
    },
    {
      field: "qty",
      headerName: "Qty",
      flex: 0.2,
      headerClassName: "email-query-header",
    },
    {
      field: "seller",
      headerName: "Seller",
      flex: 1.2,
      headerClassName: "email-query-header",
    },
    {
      field: "buyer",
      headerName: "Buyer",
      flex: 1.2,
      headerClassName: "email-query-header",
    },
    {
      field: "price",
      headerName: "B Rate",
      flex: 0.5,
      headerClassName: "email-query-header",
    },
    {
      field: "sellerPrice",
      headerName: "S Rate",
      flex: 0.5,
      headerClassName: "email-query-header",
    },
    {
      field: "driver",
      headerName: "Driver Details",
      headerClassName: "email-query-header",
      flex: 1.5,
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 130,
      headerClassName: "email-query-header",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Button
              variant="outlined"
              color={
                params?.row?.paymentStatus == "paid" ? "success" : "primary"
              }
              onClick={() => {
                if (params?.row?.paymentStatus !== "paid") {
                  setPaymentStatusModal(true);
                  setId(params?.row?.rowId);
                }
              }}
              sx={{
                width: "100%",
                cursor: params?.row?.paymentStatus == "paid" && "not-allowed",
              }}
            >
              {params?.row?.paymentStatus}
            </Button>

            {/* <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={params?.row?.paymentStatus}
                onChange={(e) => {
                  setFetchData(true);
                  orderPayment({
                    paymentStatus: e.target.value,
                    id: params?.row?.rowId,
                  });
                }}
                readOnly={
                  params?.row?.orderStatus == "complete" ||
                  params?.row?.orderStatus == "cancel" ||
                  params?.row?.status == "all"
                }
              >
                <MenuItem value="paid">Paid</MenuItem>
                <MenuItem value="unpaid">Unpaid</MenuItem>
              </Select>
            </FormControl> */}
          </Box>
        );
      },
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 172,
      headerClassName: "email-query-header",
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <FormControl fullWidth>
              <Select
                value={params?.row?.orderStatus}
                onChange={(e) => {
                  setFetchData(true);
                  if (e.target.value === "Order Processed") {
                    setVehicleDispatched(true);
                    setId(params?.row?.rowId);
                  } else {
                    orderStatusData({
                      orderStatus: e.target.value,
                      id: params?.row?.rowId,
                    });
                  }
                }}
                readOnly={
                  params?.row?.paymentStatus === "unpaid" ||
                  params?.row?.orderStatus === "Order Delivered" ||
                  params?.row?.orderStatus === "Canceled" ||
                  params?.row?.status === "all"
                }
              >
                {orderStatus?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data?.name}>
                      {data?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        );
      },
    },
    {
      field: "isConfirm",
      headerName: "Order Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      flex: 1,
      renderCell: (params) => {
        const userRoleData = userRoleAccess?.userPermissions?.find(
          (data) => data?.userModule == "order" && data?.edit,
        );
        const isAdmin = userRoleAccess?.userPermissions?.find(
          (data) => data?.userModule == "all",
        );
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            {params?.row?.status !== "active" && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setConfirmOrder(true);
                  setId(params?.row?.rowId);
                }}
                // disabled
                disabled={
                  (!userRoleData?.edit &&
                    Object.keys(isAdmin || {})?.length <= 0) ||
                  Object.keys(isAdmin || {})?.length <= 0
                }
              >
                Confirm
              </Button>
            )}
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                orderStatusData({
                  id: params?.row?.rowId,
                  orderStatus: "Canceled",
                  sellingProduct: params?.row?.sellingProduct,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
                // setConfirmOrder(true);
                // setId(params?.row?.rowId);
              }}
            >
              Cancle
            </Button>
            {/* //! old switch for incase use */}
            {/* <Switch
              checked={params?.row?.isConfirm}
              onChange={() => {
                singleOrderUpdate({
                  isConfirm: true,
                  orderStatus: "complete",
                  paymentStatus: "complete",
                  id: params?.row?.rowId,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            /> */}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      width: 110,
      renderCell: (params) => {
        return (
          <Box className="email-query-table-btn-main">
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={BorderColorIcon}
              onClick={() => {
                setOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "order" && data?.edit) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
            <ButtonComponent
              className="btn-update1"
              isIcon={true}
              OnlyIcon={RemoveRedEyeIcon}
              onClick={() => {
                setViewOpen(true);
                setId(params?.row?.rowId);
              }}
              disabledbtn={userRoleAccess?.userPermissions?.find(
                (data) =>
                  (data?.userModule == "order" && data?.view) ||
                  userRoleAccess?.userRoleType == "admin",
              )}
            />
          </Box>
        );
      },
    },
    {
      field: "invoice",
      headerName: "Download Invoice",
      sortable: false,
      disableClickEventBubbling: true,
      headerClassName: "email-query-header",
      width: 170,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClick(params?.row?.sellerInvoiceLink);
                // setId(params?.row?.rowId);
                // setTimeout(() => {
                //   handleGeneratePDFSeller();
                // }, 1000);
              }}
            >
              Seller
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClick(params?.row?.invoiceLink);
                // setId(params?.row?.rowId);
                // setTimeout(() => {
                //   handleGeneratePDFBuyer();
                // }, 1000);
              }}
            >
              Buyer
            </Button>
          </Box>
        );
      },
    },
  ];
  const commonTabData = [
    {
      label: "Pending Order",
      value: "1",
      icon: <ScheduleIcon fontSize="small" />,
      component: (
        <OrderTablePage
          columns={columns?.filter(
            (data) =>
              data?.field !== "paymentStatus" &&
              data?.field !== "invoice" &&
              data?.field !== "orderStatus",
          )}
          status="pending"
          search={search}
        />
      ),
    },
    // ---------todo:- pending ---------- //
    {
      label: "Active Order",
      value: "2",
      icon: <CheckCircleIcon fontSize="small" />,
      component: (
        <OrderTablePage
          columns={[
            ...columns.slice(0, 1),
            {
              field: "invoiceNumber",
              headerName: "Invoice Number",
              flex: 0.5,
              headerClassName: "email-query-header",
            },
            ...columns.slice(1, 12),
          ]}
          status="active"
          search={search}
          fetchData={fetchData}
          setFetchData={setFetchData}
        />
      ),
    },
    {
      label: "Completed",
      value: "3",
      icon: <VerifiedIcon fontSize="small" />,
      component: (
        <OrderTablePage
          // columns={columns?.filter(
          //   (completeData) => completeData?.field !== "isConfirm",
          // )}
          columns={[
            ...columns.slice(0, 1),
            {
              field: "invoiceNumber",
              headerName: "Invoice Number",
              flex: 0.5,
              headerClassName: "email-query-header",
            },
            ...columns.slice(1, 11),
            ...columns.slice(13),
          ]}
          status="complete"
          search={search}
        />
      ),
    },
    {
      label: "Cancel",
      value: "4",
      icon: <CancelIcon fontSize="small" />,
      component: (
        <OrderTablePage
          // columns={columns?.filter(
          //   (completeData) => completeData?.field !== "isConfirm",
          // )}
          columns={[
            ...columns.slice(0, 1),
            {
              field: "invoiceNumber",
              headerName: "Invoice Number",
              flex: 0.5,
              headerClassName: "email-query-header",
            },
            ...columns.slice(1, 11),
          ]}
          status="cancel"
          search={search}
        />
      ),
    },
    {
      label: "All Order",
      value: "5",
      icon: <PeopleRoundedIcon fontSize="small" />,
      component: (
        <OrderTablePage
          columns={[
            ...columns.slice(0, 1),
            {
              field: "invoiceNumber",
              headerName: "Invoice Number",
              flex: 0.5,
              headerClassName: "email-query-header",
            },
            ...columns.slice(1, 11),
            ...columns.slice(13),
          ]}
          status="all"
          search={search}
        />
      ),
    },
    // ! for future use order history
    // {
    //   label: "Order History",
    //   value: "6",
    //   icon: <HistoryIcon fontSize="small" />,
    //   component: (
    //     <OrderTablePage columns={columns} status="history" search={search} />
    //   ),
    // },
  ];

  return (
    <Box className="email-query-box">
      <BreadCrumb
        title="Order Module"
        perentTabName="order"
        // childTabName="Expense"
        // openMenuClick={toggleDrawer("bottom", true)}
      />
      <Box className="email-query-box-main">
        <Box>
          <TextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            onChange={(e) => setSearch(e.target.value)}
            sx={{ width: "400px" }}
            inputProps={{ placeholder: "Search here...." }}
          />
        </Box>
        <ButtonComponent
          name="Add order"
          isName={true}
          Icon={AddIcon}
          onClick={() => setOpen(true)}
          disabledbtn={userRoleAccess?.userPermissions?.find(
            (data) =>
              (data?.userModule == "order" && data?.add) ||
              userRoleAccess?.userRoleType == "admin",
          )}
        />
      </Box>
      <CommonTab data={commonTabData} />
      <div ref={targetRef} style={{ display: "none", minHeight: "100vh" }}>
        <SellerInvoice id={id} />
      </div>
      <div ref={targetRefBuyer} style={{ display: "none", minHeight: "100vh" }}>
        <BuyerInvoice id={id} />
      </div>
      {open && (
        <CustomizedModal
          isOrderSection={true}
          isHeadingName={true}
          heading="Order Form"
          open={open}
          onClick={() => {
            setOpen(false);
            setId("");
          }}
        >
          <OrderForm
            onClick={() => {
              setOpen(false);
              setId("");
            }}
            isEdit={id ? true : false}
            // singleData={singleListOrderData}
            id={id}
          />
        </CustomizedModal>
      )}
      {viewOpen && (
        <CustomizedModal
          isOrderSection={true}
          isHeadingName={false}
          heading="Order Form"
          open={viewOpen}
          onClick={() => {
            setViewOpen(false);
            setId("");
          }}
        >
          <OrderView id={id} />
        </CustomizedModal>
      )}
      {confirmOrder && (
        <CustomizedModal
          isHeadingName={true}
          heading="Confirm Order Form"
          open={confirmOrder}
          id={id}
          onClick={() => {
            setConfirmOrder(false);
            setId("");
          }}
        >
          <ConfirmOrder
            id={id}
            onClick={() => {
              setConfirmOrder(false);
              setId("");
            }}
          />
        </CustomizedModal>
      )}
      {vehicleDispatched && (
        <CustomizedModal
          isHeadingName={true}
          heading="Vehical Information"
          open={vehicleDispatched}
          id={id}
          onClick={() => {
            setVehicleDispatched(false);
            setId("");
          }}
        >
          <VehicalInformationOrder
            id={id}
            onClick={() => {
              setVehicleDispatched(false);
              setId("");
            }}
          />
        </CustomizedModal>
      )}
      {paymentStatusModal && (
        <CustomizedModal
          isHeadingName={true}
          heading="Payment Status Form"
          open={paymentStatusModal}
          id={id}
          onClick={() => {
            setPaymentStatusModal(false);
            setId("");
          }}
        >
          <PaymentStatus
            itemId={id}
            // fetchData={fetchData}
            setFetchData={setFetchData}
            setId={setId}
            orderPayment={orderPayment}
            onClick={() => {
              setPaymentStatusModal(false);
              setId("");
            }}
          />
        </CustomizedModal>
      )}
    </Box>
  );
};

export default OrderModule;
